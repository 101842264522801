<template>
  <div>
    <template v-if="data">
      <div>
        <v-subheader
          v-if="type>0"
          class="d-inline-flex wee-text-head"
        >
          <v-icon class="mr-5">mdi-account-check</v-icon> {{$t('app.checkinUserType'+type)}}
        </v-subheader>

        <!-- <h1 class="float-right d-inline-flex text-h3 font-weight-bold"> -->
        <div class="d-inline-flex float-right text-h5 font-weight-bold">
          <span :style="'color:'+data.color">{{Number(data.current).toLocaleString('th')}}</span>
          <span class="">/{{Number(data.limit).toLocaleString('th')}}</span>
        </div>
      </div>
    </template>
    <apexchart
      :height="height"
      type="area"
      :options="chartOptions"
      :series="series"
      ref="realtimeChartByType"
    />
  </div>
</template>
<script>
import {
  reactive,
  toRefs,
  defineComponent,
  ref,
  watch
} from "@vue/composition-api";
export default defineComponent({
  components: {
    apexchart: () => import("vue-apexcharts")
  },
  props: {
    value: 0,
    dark: {
      type: Boolean,
      default: false
    },
    type: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 200
    },
    data: {
      type: Object,
      default: null
    },
    charts: {
      type: Object,
      default: null
    },
    categories: {
      type: Array,
      default: null
    },
    strokestyle: {
      type: String,
      default: "smooth" //smooth, straight, stepline
    },
    colors: {
      type: Array,
      default: null
    }
  },
  setup(props, { refs }) {
    const series = ref(props.charts.series);
    const chartData = reactive({
      chartOptions: {
        chart: {
          type: "area",
          toolbar: {
            show: false
          },
          background: props.dark ? "#000000" : "#fff"
        },
        theme: {
          mode: props.dark ? "dark" : "light"
        },
        dataLabels: {
          enabled: false
        },
        grid: {
          borderColor: props.dark ? "#353537" : "#e9ebec" //transparent
          // row: {
          //   colors: [props.dark ? "#353537" : "#e9ebec", "transparent"], // takes an array which will be repeated on columns
          //   opacity: 0.4
          // }
        },
        stroke: {
          width: 1,
          curve: props.strokestyle
        },
        xaxis: {
          type: "datetime",
          categories: props.categories
        },
        tooltip: {
          x: {
            format: "dd/MM/yy HH:mm"
          }
        },
        colors: props.colors ? props.colors : ["#23D160", "#FF3860"]
      }
    });
    watch(
      () => props.value,
      val => {
        if (val) {
          if (!refs.realtimeChartByType) {
            return;
          }

          const cateList = chartData.chartOptions.xaxis.categories;
          // console.log(cateList);
          const position = cateList.indexOf(val.category);
          if (position >= 0) {
            if (series.value.length > 0) {
              const seriesList = series.value;
              const checkInList = seriesList[0].data;
              const checkOutList = seriesList[1].data;

              //checkin
              const inNow = checkInList[position];
              const outNow = checkOutList[position];
              if (val.in > 0 || val.out > 0) {
                if (inNow != val.in) {
                  series.value[0].data[position] = val.in;
                }
                if (outNow != val.out) {
                  series.value[1].data[position] = val.out;
                }

                refs.realtimeChartByType.updateSeries(series.value, true);
              }
            }
          } else {
            chartData.chartOptions.xaxis.categories.push(val.category);
            series.value[0].data.push(val.in);
            series.value[1].data.push(val.out);
            refs.realtimeChartByType.updateSeries(series.value, true);
            refs.realtimeChartByType.updateOptions(chartData, true);
          }
        }
      }
    );

    watch(
      () => props.dark,
      val => {
        if (!refs.realtimeChartByType) {
          return;
        }
        updateTheme(val);
      }
    );
    const updateTheme = dark => {
      refs.realtimeChartByType.updateOptions({
        chart: { background: dark ? "#000000" : "#fff" },
        theme: { mode: dark ? "dark" : "light" },
        grid: { borderColor: dark ? "#353537" : "#e9ebec" }
      });
    };
    return {
      ...toRefs(chartData),
      series
    };
  }
});
</script>